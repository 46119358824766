/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/react';
import tw, { css } from 'twin.macro';
import Page from '../components/layout/Page';
import Column from '../components/Column';
import SubTitle from '../components/SubTitle';

const videoPage = () => (
  <Page title="課程影片">
    <Column>
      <SubTitle>2023 金融資安研習營-Day 1</SubTitle>
      <iframe
        tw="h-56 w-full md:h-96 md:max-w-3xl"
        src="https://www.youtube.com/embed/cgaHpr_Op7Y"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </Column>
    <Column>
      <SubTitle>2023 金融資安研習營-Day 2</SubTitle>
      <iframe
        tw="h-56 w-full md:h-96 md:max-w-3xl"
        src="https://www.youtube.com/embed/0qV1VbHEpnQ"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </Column>
  </Page>
);

export default videoPage;
